// Copyright 2015-2021 Swim.inc
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/** @public */
export type InitType<I> =
  I extends {init(init: infer T): any} ? T : never;

/** @public */
export interface Initable<T> {
  init(init: T): void;
}

/** @public */
export const Initable = (function () {
  const Initable = {} as {
    is<T>(object: unknown): object is Initable<T>;
  };

  Initable.is = function <T>(object: unknown): object is Initable<T> {
    if (typeof object === "object" && object !== null || typeof object === "function") {
      const init = object as Initable<T>;
      return "init" in init;
    }
    return false;
  };

  return Initable;
})();
